

const region = "eu-west-2";
const env = process.env.REACT_APP_ENV;
const cognito = {
    "devtwo": {
        "userPool": "eu-west-2_8TuEHTOB5",
        "clientId": "fjbrqp1nnjbjpvoir5tnl3fou"
    },
    "test": {
        "userPool": "eu-west-2_yT2oNee9S",
        "clientId": "3s6ho2eh7f0q4konnldmf3075t"
    },
    "live": {
        "userPool": "eu-west-2_VlGWf4CQ5",
        "clientId": "52udq1i5larhh3upkfnqfpi60r"
    }
}

let hostOrigin;

if (typeof window !== "undefined" && !!window.location) {
    hostOrigin = window.location.origin;
}

export default {
    aws_project_region: region,
    aws_cognito_region: region,
    aws_user_files_s3_bucket_region: region,
    aws_user_pools_id: cognito[ env ].userPool,
    aws_user_pools_web_client_id: cognito[ env ].clientId,
    aws_user_files_s3_bucket: "thefloorr-storage-" + env,
    Auth: { 
        clientMetadata: { 
            app: "web" 
        }
    },
    oauth: {
        domain: `thefloorr-auth-${ env }.auth.eu-west-2.amazoncognito.com`,
        redirectSignIn: `${ hostOrigin }/signin/`,
        redirectSignOut: `${ hostOrigin }/`,
        responseType: "code",
        scope: [
            "email",
            "openid"
        ]
    },
    federationTarget: "COGNITO_USER_POOLS"
}