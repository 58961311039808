import { useState, useEffect, useMemo } from "react"

function useHtmlContent(name) {
    const [ content, setContent ] = useState("");
    const html = useMemo(() => ({ __html: content }), [ content ]);

    useEffect(
        () => {
            if (name) {
                fetch(`https://assets.thefloorr.com/content/${ name }.html`, { mode: "cors" })
                    .then(r => r.text())
                    .then(setContent);
            }
        },
        [ name ]
    );

    return html;
}

export default useHtmlContent