import { useCallback, useEffect } from "react"
import useDictRef from "common/src/hooks/useDictRef";

function useScrollEvent(eventSource = window, scrollSource = document.documentElement, options = {}) {

    const ref = useDictRef({});
    if (ref.st === undefined) {
        ref.st = 0;
    }

    const onScroll = useCallback(
        () => {
            const st = scrollSource.scrollTop;
            const offset = options.offset || 10;
            let direction;

            
            if (ref.st < st) {
                direction = "down";
                if (ref.st <= offset && st > offset) {
                    options.onScrolled && options.onScrolled(true);
                }
            }
            else {
                if (ref.st > st) {
                    direction = "up";
                }
                if (ref.st > offset && st <= offset) {
                    options.onScrolled && options.onScrolled(false);
                }
            }

            if (st <= 0) {
                direction = null;
            }

            if (ref.direction !== direction) {
                options.onDirectionChange && options.onDirectionChange(direction);
            }

            ref.direction = direction;
            ref.st = st;
        },
        // eslint-disable-next-line
        [ ]
    );

    useEffect(
        () => {
            eventSource.addEventListener("scroll", onScroll);
            return () => {
                eventSource.removeEventListener("scroll", onScroll);
            }
        },
        // eslint-disable-next-line
        []
    );

}

export default useScrollEvent