
import { useMemo } from "react"
import NavSecondary from "./nav/Secondary"
import NavSocial from "./nav/Social"

function Footer() {

    const year = useMemo(() => (new Date()).getFullYear(), []);
  
    return (
        <div className="footer">
            <div className="footer-social">
                <NavSocial/>
            </div>

            <div className="footer-links">
                <NavSecondary/>
            </div>

            <div className="footer-copy">
                <p>Copyright © { year } THE FLOORR. THE FLOORR Ltd is registered in 
                England and Wales. <nobr>All rights reserved.</nobr></p>
            </div>
        </div>
    )
}

export default Footer