import Authenticator from "common/src/components/authenticator/Authenticator"
//import Footer from "app/components/Footer"
import useHtmlClass from "app/hooks/useHtmlClass"

function Signin() {

    useHtmlClass("html-page-signin");

    return (
        <>
        <div className="page-signin">
            <Authenticator 
                authMode="signIn" 
                showRegister={ false }
                signinProps={{
                    labelEmail: "Email Address",
                    submitText: "Log In",
                    linkForgotText: "Forgot your password?",
                    subheader: (
                        <p className="form-sub-header">
                            If you haven't registered yet, please do so in the app.<br/>
                            To download the app {" "}
                            <a rel="noreferrer" href="https://apps.apple.com/us/app/the-floorr-for-stylists/id6451112463">click here</a>.
                        </p>
                    )
                }}/>
        </div>
        {/*<Footer/>*/}
        </>
    )
}

export default Signin