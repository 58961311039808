import Footer from "app/components/Footer"
import useHtmlClass from "app/hooks/useHtmlClass"

function About() {

    useHtmlClass("html-page-about");

    return (
        <>
        <div className="page-about">

            <div className="page-about-what-is-img"/>
            <div className="page-about-what-is">
                <h2 className="h-primary">What is <nobr>THE FLOORR</nobr>?</h2>
                <p>THE FLOORR is the revolutionary, personal shopping engine that powers a curated, 
                    luxury shopping experience between style experts and their clients. 
                    Together, clients receive a remarkable global inventory of wonderful 
                    fashion and style experts optimize and expand their business like never before.</p>
                <br/><br/>
                <p><span>Personal Shoppers and Stylists</span><br/>
                Streamlining a historically fragmented workflow, THE FLOORR app gives Personal Shoppers 
                and Stylists a simple and seamless, integrated platform on which to grow their 
                business and deliver a fully-rounded, best-in-class service to clients.</p>
                <br/><br/>
                <p><span>Clients</span><br/>
                Our technology is uniquely designed to deliver desire, convenience and 
                personalization to luxury clients worldwide &mdash; all in one place. 
                Clients on THE FLOORR can easily access millions of luxury categories 
                from the most exciting stores globally, within a fully personalized, 1-2-1 experience.</p>
            </div>
            <blockquote className="quote page-about-what-is-quote">
                “Our software acts as an extension of your styling business. We provide the tools 
                for you to create, communicate, earn and sell in the most seamless way.”
            </blockquote>
            

            <div className="page-about-difference-img"/>
            <div className="page-about-difference">
                <h2 className="h-primary">What makes us different?</h2>
                <ul>
                    <li>Our pioneering technology delivers an unparalleled, 360-degree, 1-2-1 experience that caters perfectly to the unique needs and preferences of the personal shopping market.</li>
                    <li>Our members earn through pre-agreed commission on a global inventory of the world's most coveted brands.</li>
                    <li>Our in-built chat feature enables product sharing, effective communication and instant feedback to truly optimize client relationships.</li>
                </ul>
            </div>
                
            <div className="page-about-how-do-i-use-img"/>
            <div className="page-about-how-do-i-use">
                <h2 className="h-primary">How do I use <nobr>THE FLOORR</nobr>?</h2>
                <p><span>Personal Shoppers and Stylists</span><br/>
                For those in a client-facing capacity within the luxury space, we know how fragmented your way of working has been. Our app keeps everything in one place so you can track earnings, avoid missed sales - and your business can thrive.
                <br/><br/>
                <span>Clients</span><br/>
                THE FLOORR has been created to give you the very best shopping experience with your 
                personal shopper or stylist. 
                Once you've received your stylist's invitation to download the app, 
                you'll gain access to our private styling area, chat feature and so much more. 
                If your stylist is not already on the app, you can invite them to style you via {" "}
                <a href="https://apps.apple.com/us/app/the-floorr-for-clients/id6451112918" 
                    target="_blank"
                    rel="noreferrer">THE FLOORR</a>.</p>
            </div>

            <div className="page-about-how-do-we-select">
                <h2 className="h-primary">How do we select our style experts?</h2>
                <p>Our selection process ensures every expert on <nobr>THE FLOORR</nobr> 
                already has an operating personal shopping/styling luxury business or is 
                working in a client-facing capacity, looking after clients and supporting 
                their styling and/or shopping needs.</p>
            </div>

            <div className="page-about-quotes">
                <blockquote className="page-about-bq-vogue">
                    <p className="body-font">
                    “A new fashion platform based on an original premise: taking the essence of the 
                    luxury store and distilling it into a unique digital 
                    experience. <nobr>The Floorr</nobr> invites you to be inspired. 
                    With its micro-search option, 
                    you can track clothes that you already have and see them combined in juicy outfits.”
                    </p>
                </blockquote>

                <blockquote className="page-about-bq-forbes">
                    <p className="body-font">
                    “Separating itself from the fashion pack, <nobr>The Floorr</nobr> stands out 
                    because users can also view looks from the shop floor experts they admire, 
                    find the city they want to be inspired by or to see how trends are being 
                    translated there.”
                    </p>
                </blockquote>            

                <blockquote className="page-about-bq-luxury">
                    <p className="body-font">
                    “<nobr>The Floorr</nobr> operates by showing you stylised looks and complete outfits, 
                    created by retail staff from around the world, instead of individual products. 
                    So, if you're looking for the perfect work-from-home cashmere set, 
                    you can now avoid endless scrolling and instead, look for the picks and 
                    styling suggestions of these experts. It's also a brilliant tool if you're 
                    looking for new pieces to go with much-loved staples. Simple.”
                    </p>
                </blockquote>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default About