import { ApolloClient, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import getUserContext from "./getUserContext"
import settings from "common/src/settings"

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    }
};

let client;

export default async function() {

    if (client) {
        return client;
    }

    return getUserContext()
    .then(context => {

        /*const link = new GraphQLWsLink(
            createClient({
                url: settings.graphqlEndpoint.replace("https://", "wss://"),
                keepAlive: 10000, // ping server every 10 seconds
                connectionParams: {
                    headers: context.headers
                },
                on: {
                    connected: (socket) => (activeSocket = socket),
                    ping: (received) => {
                        if (!received) {// sent
                            timedOut = setTimeout(() => {
                            if (activeSocket.readyState === WebSocket.OPEN)
                                activeSocket.close(4408, 'Request Timeout');
                            }, 5000); // wait 5 seconds for the pong and then close the connection
                        }
                    },
                    pong: (received) => {
                        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
                    }
                }
            })
        );*/
        const link = new WebSocketLink({
            uri: settings.graphqlEndpoint.replace("https://", "wss://"),
            options: {
                reconnect: true,
                inactivityTimeout: 0,
                connectionParams: {
                    headers: context.headers
                }
            }
        });
        const cache = new InMemoryCache();
        client = new ApolloClient({ cache, link, defaultOptions });

        return client;
    })
}

export function hasWsClient() {
    return !!client;
}

export function invalidateWsClient() {
    try {
        client?.stop();
    }
    catch (err) {
        console.log(err);
    }
    client = null;
}