
import { useSelector } from "react-redux"
import { checkCurrent } from "common/src/actions/user"
import { useCallback, useEffect, useState } from "react"
import useDictRef from "common/src/hooks/useDictRef"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import user from "common/src/user"
import hub from "common/src/hub"

function useRoleAccess(routeMatch) {

    const [ mode, setMode ] = useState(routeMatch);
    const [ state, setState ] = useState(null);
    const { loggedIn } = useSelector(s => s.user);

    const ref = useDictRef({ routeMatch, mode, state, loggedIn });

    const checkUser = useCallback(
        () => {
            setState("checking");
            checkCurrent();
        },
        []
    );

    const onAuth = useCallback(
        () => {
            checkUser();
        },
        [ checkUser ]
    );

    useEffect(
        () => {
            if (ref.routeMatch !== "default") {
                checkUser();
                hub.listen("auth", "signIn", onAuth);
                return () => {
                    hub.remove("auth", "signIn", onAuth);
                }
            }
            else {
                setState("show");
            }
        },
        // eslint-disable-next-line
        []
    );

    useUpdateEffect(
        () => {
            if (ref.routeMatch !== "default") {
                if (!loggedIn) {
                    setState("auth");
                }
                else {
                    if (ref.routeMatch === "shopper") {
                        if (user.isOnly("User")) {
                            setMode("client");
                        }
                        setState("show");
                    }
                    else {
                        setState("show");
                    }
                }
            }
        },
        [ loggedIn ]
    )

    return { mode, state };
}

export default useRoleAccess