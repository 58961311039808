import { useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

import Button from "../Button"
import Affiliates from "app/components/Affiliates"
import Hero from "app/components/Hero"
import Footer from "app/components/Footer"
import { ReactComponent as IconExpand } from "app/svg/expand.svg"

import hub from "common/src/hub"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import useDictRef from "common/src/hooks/useDictRef"
import async from "common/src/lib/js/async"

const s3 = "https://thefloorr-assets.s3.eu-west-2.amazonaws.com/images/home-dec-23";


function Main() {

    const { search, pathname, hash } =  useLocation();
    const history = useHistory();
    const [ showOptions, setShowOptions ] = useState(false);
    const ref = useDictRef({ search, pathname });

    const onShowOptions = useSwallowEventCallback(
        () => setShowOptions(!showOptions),
        [ showOptions ]
    );

    const onPersonalShopperClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-shopper-form", true),
        []
    );
    const onBrandClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-brand-form", true),
        []
    );
    const onCustomerClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-customer-form", true),
        []
    );

    useEffect(
        () => {
            document.documentElement.classList.add("main");
            if (search === "?shopper-form") {
                history.replace(ref.pathname);
                async(() => hub.dispatch("app", "show-shopper-form", search));
            }
            else if (search === "?brand-form") {
                history.replace(ref.pathname);
                async(() => hub.dispatch("app", "show-brand-form", search));
            }
            else if (search === "?customer-form") {
                history.replace(ref.pathname);
                async(() => hub.dispatch("app", "show-customer-form", search));
            }
            if (hash === "#how-it-works") {
                async(() => {
                    const el = document.getElementById("how-it-works");
                    if (el) {
                        window.scrollTo({
                            top: el.offsetTop - 48,
                            behavior: "smooth"
                        });
                    }
                });
            }
            return () => {
                document.documentElement.classList.remove("main");
            };
        },
        // eslint-disable-next-line
        []
    );
    
    return (
        <>
        <Hero/>
        <div id="how-it-works" className="page-main">

            <div className="page-main-header">
                <h3 className="page-main-header-line-1">
                    <span>Are you a</span> Personal Shopper 
                    or Curator <span>in the luxury space?</span>
                </h3>
                <p className="page-main-header-line-2">
                    Join THE FLOORR's global community of luxury insiders
                </p>
            </div>

            <div className="page-main-feed">
                <div className="page-main-img-feed">
                    <img src={ s3 + "/iphone_feed.png" } alt=""/>
                </div>
                <div className="page-main-feed-content">
                    <h3>Global Inventory Feed & Pre-Agreed Commission Rates</h3>
                    <p>Search through millions of products and find your favorites.</p>
                </div>
                <div className="page-main-img-profile">
                    <img src={ s3 + "/iphone_profile.png" } alt=""/>
                </div>
            </div>

            <div className="affiliates">
                <div className="affiliates-carousel">
                    <div className="affiliates-page">
                        <Affiliates/>
                    </div>
                    <div className="affiliates-page">
                        <Affiliates/>
                    </div>
                </div>
            </div>

            <div className="page-main-chat">
                <div className="page-main-chat-content">
                    <h3>Chat & Engage</h3>
                    <p>Share products and looks and receive instant feedback.</p>
                </div>
                <div className="page-main-chat-img">
                    <img src={ s3 + "/iphone_chat.png" } alt=""/>
                </div>
            </div>

            <div className="page-main-styling">
                <div className="page-main-styling-content">
                    <h3>Style & Create</h3>
                    <p>Personalize and create looks in seconds for your clients.</p>
                </div>
                <div className="page-main-styling-img">
                    <img src={ s3 + "/iphone_styling.png" } alt=""/>
                </div>
            </div>

            <div className="page-main-more">
                <h3>& More...</h3>
                <Button 
                    active={ showOptions }
                    text="Apply now"
                    className="page-main-more-button"
                    onClick={ onShowOptions }>
                    <IconExpand/>
                </Button>
                <div className={ "page-main-more-options " + (showOptions ? "active" : "") }>
                    <Button 
                        text="I Am A Personal Shopper" 
                        className="page-main-more-option"
                        onClick={ onPersonalShopperClick }/>
                    <Button 
                        text="I Am A Brand Or Retailer" 
                        className="page-main-more-option"
                        onClick={ onBrandClick }/>
                    <Button 
                        text="I Am A Client" 
                        className="page-main-more-option"
                        onClick={ onCustomerClick }/>
                </div>
            </div>
    
        </div>
        <Footer/>
        </>
    )
}

export default Main