import { useState } from "react"

import Button from "app/components/Button"
import Footer from "app/components/Footer"
import FormField from "app/components/form/FormField"
import useFormData from "common/src/lib/form/useFormData"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import required from "common/src/lib/form/validator/required"
import createValidator from "common/src/lib/form/createValidator"
import isEmail from "common/src/lib/form/validator/isEmail"

import hub from "common/src/hub"
import api from "app/api"
import useDictRef from "common/src/hooks/useDictRef"
//import { alert } from "common/src/components/dialog/Alert"

const fields = [
    {
        name: "name",
        validator: createValidator(required, "Please enter your name")
    },
    {
        name: "email",
        validator: [
            createValidator(required, "Please enter your email"),
            createValidator(isEmail, "Please enter a valid email address")
        ]
    },
    {
        name: "message",
        validator: createValidator(required, "Please enter your message")
    }
]

function PageContact() {

    const [ submitting, setSubmitting ] = useState(false);
    const {
        validateAll, setErrorMode, resetAll,
        name, nameError, nameChange,
        email, emailError, emailChange,
        message, messageError, messageChange
    } = useFormData(fields);

    const refs = useDictRef({
        name, email, message
    });

    const onSubmit = useSwallowEventCallback(
        async () => {

            const valid = await validateAll();

            if (!valid) {
                setErrorMode(true);
                return;
            }

            setSubmitting(true);

            await api.unauth.post("/contact", {
                body: {
                    name: refs.name,
                    email: refs.email,
                    message: refs.message
                }
            });

            setSubmitting(false);
            setErrorMode(false);
            resetAll();

            hub.dispatch("alert", "show", {
                title: "Thank you for your message",
                message: (
                    <>
                        It's been received, and we'll be in touch soon.
                        <br/><br/>
                        Best wishes from THE FLOORR.
                    </>
                ),
                overlay: true
            });
        },
        []
    );

    return (
        <>
        <div className="page-contact">

            <div className="page-contact-form">
                <h2 className="h-primary">Contact Us</h2>
                <p>For any general inquiries, please send us a message.</p>

                <div className="page-contact-form-fields">
                    <FormField
                        value={ name }
                        onChange={ nameChange }
                        error={ nameError }
                        onSubmit={ onSubmit }
                        inputProps={{
                            placeholder: "Your Name"
                        }}/>
                    <FormField
                        value={ email }
                        onChange={ emailChange }
                        error={ emailError }
                        onSubmit={ onSubmit }
                        inputProps={{
                            placeholder: "Your Email"
                        }}/>
                    <FormField
                        value={ message }
                        onChange={ messageChange }
                        error={ messageError }
                        multiline
                        className="page-contact-message"
                        inputProps={{
                            placeholder: "Your Message"
                        }}/>

                    <Button 
                        text="Submit" 
                        variant="default" 
                        loading={ submitting } 
                        disabled={ submitting }
                        onClick={ onSubmit }/>
                </div>
            </div>

            <div className="page-contact-contacts">

                <div className="page-contact-affiliates">
                    <h3>Affiliates</h3>
                    <a href="mailto:affiliates@thefloorr.com">affiliates@thefloorr.com</a>
                </div>

                <div className="page-contact-press">
                    <h3>Press</h3>
                    <a href="mailto:press@thefloorr.com">press@thefloorr.com</a>
                </div>

                <div className="page-contact-concierge">
                    <h3>Help</h3>
                    <a href="mailto:help@thefloorr.com">help@thefloorr.com</a>
                </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default PageContact