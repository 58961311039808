
import { useCallback, useState } from "react"
import { Link } from "react-router-dom"

import FormFieldContainer from "app/components/form/FormFieldContainer"
import SelectField from "app/components/form/Select"
import FormField from "app/components/form/FormField"
import Button from "app/components/Button"
import Checkbox from "app/components/form/Checkbox"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import useDictRef from "common/src/hooks/useDictRef"
import isEmail from "common/src/lib/form/validator/isEmail"
import hub from "common/src/hub"
import api from "app/api"

import required from "common/src/lib/form/validator/required"
import createValidator from "common/src/lib/form/createValidator"
import useFormData from "common/src/lib/form/useFormData"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"


async function validateEmail(email) {
    const res = await api.unauth.post("/brand/validate-email", { body: { email }});
    return res?.success || false;
}

const interests = [
    {
        value: "affiliate",
        label: "Becoming an Affiliate Partner"
    },
    {
        value: "saas",
        label: "SaaS Services"
    },
    {
        value: "clientele",
        label: "Clientele Consulting"
    }
]

function renderInterest(value) {
    return interests.find(i => i.value === value)?.label || "Select your interest";
}

const fields = [
    {
        name: "name",
        validator: createValidator(required, "Please enter the name of the brand")
    },
    {
        name: "email",
        validator: [
            createValidator(required, "Please enter your email"),
            createValidator(isEmail, "Please enter a valid email"),
            createValidator(validateEmail, "Sorry, you can't use this email")
        ]
    },
    {
        name: "interest",
        validator: createValidator(required, "Please let us know what you are instered in"),
        default: ""
    },
    {
        name: "bio"
    },
    {
        name: "agree",
        validator: createValidator(required, "You have to agree to our terms and conditions")
    }
]


function BrandForm() {

    const {
        validateAll, setErrorMode, resetAll,
        name, nameError, nameChange,
        email, emailError, emailChange,
        interest, interestError, interestChange,
        bio, bioError, bioChange,
        agree, agreeError, agreeChange
    } = useFormData(fields);

    const [ saving, setSaving ] = useState(false);

    const ref = useDictRef({
        name, email, agree,
        interest, bio
    });
    

    const onSubmit = useCallback(
        async () => {
            setSaving(true);

            const valid = await validateAll();

            if (valid) {
                
                await api.unauth.post("/ec/apply", {
                    body: {
                        name: ref.name,
                        interest: ref.interest,
                        email: ref.email,
                        bio: ref.bio,
                        role: "brand",
                        createdVia: "website/brand/form"
                    }
                });

                hub.dispatch("app", "show-brand-form", false);
                hub.dispatch("alert", "show", {
                    overlay: true,
                    variant: "default-inverted",
                    title: "We're building something special for you.",
                    message: "Thank you for joining THE FLOORR community - luxury's secret weapon. " +
                            "A place for the customer facing community, luxury brands and clients to thrive."
                });

                resetAll();
            }
            else {
                setErrorMode(true);
            }
            setSaving(false);
        },
        // eslint-disable-next-line
        []
    );

    const onCloseClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-brand-form", false),
        []
    );

    return (
        <div className="connect-dialog-container">
        <div className="connect-dialog">
            
            <div className="connect-form">
                <h2 className="h-primary connect-dialog-header">Brand or Retailer</h2>
                <p className="connect-dialog-subheader">If you are a Brand or a Retailer please fill this form. 
                If you are a Client or if you are a Personal Shopper please go back.</p>

                <div className="form-row">
                    <FormFieldContainer error={ interestError }>
                        <SelectField
                            name="interest"
                            variant="transparent"
                            value={ interest }
                            error={ interestError }
                            onChange={ interestChange }
                            options={ interests }
                            renderValue={ renderInterest }
                            emptyOption={ true }/>
                    </FormFieldContainer>
                </div>
                <div className="form-row">
                    <FormField
                        name="name"
                        variant="transparent"
                        value={ name }
                        error={ nameError }
                        onSubmit={ onSubmit }
                        onChange={ nameChange }
                        inputProps={{
                            placeholder: "Name *"
                        }}/>
                </div>
                
                <div className="form-row">
                    <FormField
                        name="email"
                        variant="transparent"
                        value={ email }
                        error={ emailError }
                        onSubmit={ onSubmit }
                        onChange={ emailChange }
                        inputProps={{
                            placeholder: "Email *"
                        }}/>
                </div>
                <div className="form-row">
                    <FormField
                        name="bio"
                        variant="transparent"
                        value={ bio }
                        error={ bioError }
                        onSubmit={ onSubmit }
                        onChange={ bioChange }
                        multiline
                        inputProps={{
                            placeholder: "Additional Info"
                        }}/>
                </div>
                
                
                <div className="form-row">
                    <Checkbox
                        checked={ agree }
                        error={ agreeError }
                        onChange={ agreeChange }
                        label={ 
                            <p className="connect-terms">
                                By submitting this form you agree to our&nbsp;
                                <Link to="/terms" target="_blank" rel="noreferrer noopener">
                                    <nobr>terms of use</nobr>
                                </Link>&nbsp;
                                and&nbsp;
                                <Link to="/privacy" target="_blank" rel="noreferrer noopener">
                                    <nobr>privacy notice</nobr>
                                </Link>.</p> }/>
                </div>

                <Button 
                    text="Apply Now"
                    variant="white"
                    loading={ saving }
                    onClick={ onSubmit }
                    className="connect-action" 
                    disabled={ saving }/>
                <Button 
                    text="Go Back"
                    variant="text"
                    className="connect-action-close"
                    onClick={ onCloseClick }/>
            </div>
        </div>

        <a href="/#" className="connect-dialog-close" onClick={ onCloseClick }>
            <IconClose/>
        </a>
        </div>
    )
}

export default BrandForm
