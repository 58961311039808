import { useCallback, useState } from "react"
import { NavLink } from "react-router-dom"
import async from "common/src/lib/js/async"

function samePageScroll(e) {
    const targetPath = e.target.href.replace(window.location.origin, "");
    const currentPath = window.location.pathname;
    if (targetPath === currentPath) {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}

function NavPrimary({ expandableAbout = false }) {

    const [ showAboutSub, setShowAboutSub ] = useState(false);

    const onHomeClick = useCallback(
        () => {
            setShowAboutSub(false);
            async(() => {
                const el = document.getElementById("how-it-works");
                if (el) {
                    window.scrollTo({
                        top: el.offsetTop - 48,
                        behavior: "smooth"
                    });
                }
            });
        },
        []
    );

    const onAboutClick = useCallback(
        (e) => {
            if (expandableAbout) {
                e.preventDefault();
                e.stopPropagation();
                setShowAboutSub(prev => !prev);
            }
            samePageScroll(e);
        },
        [ expandableAbout ]
    );

    const onAnyClick = useCallback(
        (e) => {
            
            setShowAboutSub(false);
            samePageScroll(e);
        },
        []
    );

    return (
        <>
        <div className="top-menu-about">
            <NavLink exact to="/about" onClick={ onAboutClick } className="nav-primary">About</NavLink>
            <NavLink to="/about/founder" onClick={ onAnyClick } className="nav-primary top-menu-founder">Our Founder</NavLink>
        </div>
        { showAboutSub && 
            <div className="subnav">
                <NavLink exact to="/about" onClick={ onAnyClick } className="nav-primary">THE FLOORR</NavLink>
                <NavLink to="/about/founder" onClick={ onAnyClick } className="nav-primary">Our Founder</NavLink>
            </div> }
        <NavLink to="/mission" onClick={ onAnyClick } className="nav-primary">Our mission</NavLink>
        <NavLink exact to="/#how-it-works" onClick={ onHomeClick } className="nav-primary">How it works</NavLink>
        <NavLink to="/contact" onClick={ onAnyClick } className="nav-primary">Contact us</NavLink>
        </>
    )
}

export default NavPrimary