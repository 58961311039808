
import { useEffect } from "react"
import Footer from "app/components/Footer"
import useHtmlContent from "common/src/hooks/useHtmlContent"
import async from "common/src/lib/js/async"
import useTermsAccess from "app/hooks/useTermsAccess"

function findPreviousElement(node) {
    let prev = node.previousSibling || node.parentNode;
    while (prev.nodeType === 3) {
        prev = prev.previousSibling || prev.parentNode;
    }
    return prev;
}

function linkHandler(e) {
    if (e.target.href.indexOf("#") !== -1) {
        const hash = e.target.href.split("#").pop();
        const el = document.getElementById(hash);
        const prev = findPreviousElement(el);
        prev.scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
    }
}

function prepareLinks() {
    const els = document.querySelectorAll(".terms a");

    els.forEach(a => {
        a.removeEventListener("click", linkHandler);
        a.addEventListener("click", linkHandler)
    })
}
function Terms(props) {

    const routeRole = props.match?.params?.mode || "default";
    const { mode, state } = useTermsAccess(routeRole);
    const file = mode === "default" ?
                    "terms" :
                    mode === "shopper" ?
                        "pse-terms" :
                        "ec-terms";

    const html = useHtmlContent(state === "show" ? file : null);

    useEffect(
        () => {
            async(prepareLinks, 500);
        },
        [ html ]
    )

    return (
            <>
            <div className="page page-terms">
                <div className="terms" dangerouslySetInnerHTML={ html }/>
            </div>  
            <Footer/>
            </>
    )
}

export default Terms