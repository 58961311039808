
const settings = {
    "siteName": "THE FLOORR",
    "app": "web",
    "allowGroups": "*",
    "consultationsPerPage": 16,
    "looksPerPage": 14,
    "itunesId": "6451112918",
    "appStoreUrl": "https://apps.apple.com/us/app/the-floorr-client/id6451112918"
};

export default settings;