import { batch } from "react-redux"
import hub from "common/src/hub"
import store from "app/store"
import { data } from "common/src/store/user"


// signed out via Auth.signOut
const onPoolUserSignOut = (userData) => {
    hub.dispatch("app-auth", "stateChange", false);
    batch(() => {
        store.dispatch(data.loggedIn(false));
        store.dispatch(data.id(null));
        store.dispatch(data.current.reset());
    });
}


const listeners = {
    init: () => {

        !hub.hasListener("auth", "signOut", onPoolUserSignOut) &&
            hub.listen("auth", "signOut", onPoolUserSignOut);
    }
}

export default listeners;