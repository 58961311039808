import { useState, useCallback, useEffect, useMemo } from "react"
import { Amplify } from '@aws-amplify/core'
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom"
import { ThemeProvider } from "@mui/material"
import { PostHogProvider } from "posthog-js/react"

import PageMain from "./components/page/Main"
import PageTerms from "./components/page/Terms"
import PagePrivacy from "./components/page/Privacy"
import PageCookies from "./components/page/Cookies"
import PageAcceptableUsePolicy from "./components/page/AcceptableUsePolicy"
//import PageConnect from "./components/page/Connect"
import PageEmail from "./components/page/Email"
import PageAbout from "./components/page/AboutV2"
import PageFounder from "./components/page/FounderV2"
import PageMission from "./components/page/Mission"
import PageContact from "./components/page/Contact"
import PageFaq from "./components/page/FAQ"
import PageSignin from "./components/page/Signin"
import PageDownload from "./components/page/Download"

//import PageAppLink from "common/src/components/AppLinkPage"

import ScrollToTop from "./components/ScrollToTop"
import TopMenu from "./components/TopMenu"
import BurgerMenu from "./components/BurgerMenu"
import Alert from "./components/Alert"
import CommonAlert from "common/src/components/dialog/Alert"

import PersonalShopperForm from "./components/form/PersonalShopper"
import BrandForm from "./components/form/BrandForm"
import CustomerForm from "./components/form/CustomerForm"

import useHub from "common/src/hooks/useHub"
import awsconfig from "common/src/awsConfig"
import listeners from "app/listeners"
import api from "app/api"
import { checkCurrent } from "common/src/actions/user"

import useWindowScrolled from "app/hooks/useScrollEvent"
import muiTheme from "app/muiTheme"
import useDictRef from "common/src/hooks/useDictRef"
import async from "common/src/lib/js/async"
import { posthog } from "common/src/lib/analytics/posthog"

Amplify.configure(awsconfig);
listeners.init();
api.init();

//import hub from "common/src/hub"


/*function toggleNoScroll(state) {
    if (state) {
        //document.body.classList.add("no-scroll");
    }
    else {
        //document.body.classList.remove("no-scroll");
    }
}*/


function App() {

    
    const [ showPSEForm, setShowPSEForm ] = useState(false);
    const [ showBrandForm, setShowBrandForm ] = useState(false);
    const [ showCustomerForm, setShowCustomerForm ] = useState(false);
    const [ prevScrollTop, setPrevScrollTop ] = useState(0);

    const [ alertMessage, setAlertMessage ] = useState("");
    const [ alertTitle, setAlertTitle ] = useState("");
    const [ alertOverlay, setAlertOverlay ] = useState(false);

    const ref = useDictRef({ prevScrollTop });

    const onTogglePSEForm = useCallback(
        (state) => {
            state && setPrevScrollTop(document.documentElement.scrollTop);
            try { state && window.scrollTo({ top: 0 }); } catch (err) {}
            setShowPSEForm(state);
            !state && async(() => {
                try { window.scrollTo({ top: ref.prevScrollTop }); } catch (err) {}
            });
            //toggleNoScroll(state);
        },
        // eslint-disable-next-line
        []
    );

    const onToggleBrandForm = useCallback(
        (state) => {
            state && setPrevScrollTop(document.documentElement.scrollTop);
            try { state && window.scrollTo({ top: 0 }); } catch (err) {}
            setShowBrandForm(state);
            !state && async(() => {
                try { window.scrollTo({ top: ref.prevScrollTop }); } catch (err) {}
            });
            //toggleNoScroll(state);
        },
        // eslint-disable-next-line
        []
    );

    const onToggleCustomerForm = useCallback(
        (state) => {
            state && setPrevScrollTop(document.documentElement.scrollTop);
            try { state && window.scrollTo({ top: 0 }); } catch (err) {}
            setShowCustomerForm(state);
            !state && async(() => {
                try { window.scrollTo({ top: ref.prevScrollTop }); } catch (err) {}
            });
            //toggleNoScroll(state);
        },
        // eslint-disable-next-line
        []
    );

    const onWindowScrolled = useCallback(
        (scrolled) => {
            if (scrolled) {
                document.documentElement.classList.add("scrolled"); 
            }
            else {
                document.documentElement.classList.remove("scrolled"); 
            }
        },
        []
    );

    const onWindowScrollDirection = useCallback(
        (direction) => {
            document.documentElement.classList.remove("scroll-up"); 
            document.documentElement.classList.remove("scroll-down"); 
            if (direction) {
                document.documentElement.classList.add("scroll-" + direction); 
            }
        },
        []
    );

    useWindowScrolled(window, document.documentElement, {
        onScrolled: onWindowScrolled,
        onDirectionChange: onWindowScrollDirection
    });

    const onAlertShow = useCallback(
        (message) => {
            if (typeof message === "string") {
                setAlertMessage(message);
                setAlertTitle("");
                setAlertOverlay(false);
            }
            else {
                setAlertMessage(message.message);
                setAlertTitle(message.title);
                setAlertOverlay(message.overlay || false);
            }

        },
        []
    );

    const onAlertDismiss = useCallback(
        () => setAlertMessage(""),
        []
    );

    const formMode = useMemo(
        () => showPSEForm || showCustomerForm || showBrandForm,
        [ showPSEForm, showCustomerForm, showBrandForm ]
    );

    useHub("app", "show-shopper-form", onTogglePSEForm);
    useHub("app", "show-brand-form", onToggleBrandForm);
    useHub("app", "show-customer-form", onToggleCustomerForm);

    useHub("alert", "show", onAlertShow);
    useHub("alert", "dismiss", onAlertDismiss);

    useEffect(
        () => {
            checkCurrent().catch(err => console.log(err));
        },
        []
    );

    return (
        <PostHogProvider client={ posthog } autocapture>
        <ThemeProvider theme={ muiTheme }>
        <BrowserRouter>

            { !formMode && 
            <>
            <TopMenu/>
            <ScrollToTop/>

            
            <Switch>
                <Route exact path="/" component={ PageMain }/>
                <Route exact path="/terms/:mode?" component={ PageTerms }/>
                <Route exact path="/privacy" component={ PagePrivacy }/>
                <Route exact path="/acceptable-use-policy" component={ PageAcceptableUsePolicy }/>
                <Route exact path="/cookies" component={ PageCookies }/>
                <Route exact path="/about" component={ PageAbout }/>
                <Route exact path="/about/founder" component={ PageFounder }/>
                <Route exact path="/mission" component={ PageMission }/>
                <Route exact path="/contact" component={ PageContact }/>
                <Route exact path="/faq/:mode?" component={ PageFaq }/>
                <Route exact path="/signin" component={ PageSignin }/>
                <Route path="/app/verify-email" component={ PageEmail }/>
                <Route path="/app/signin" component={ PageDownload }/>
                <Route path="/app" component={ PageDownload }/>
                <Route path="/connect/:code" component={ PageDownload }/>
                <Redirect key="redir" path="*" to="/"/>
            </Switch></> }

            { showPSEForm && <PersonalShopperForm useBack={ showPSEForm !== true }/> }
            { showBrandForm && <BrandForm useBack={ showPSEForm !== true }/> }
            { showCustomerForm && <CustomerForm useBack={ showPSEForm !== true }/> }
            { alertMessage && 
                <Alert 
                    message={ alertMessage } 
                    title={ alertTitle } 
                    overlay={ alertOverlay }/> }

            <BurgerMenu/>
            <CommonAlert/>

        </BrowserRouter>
        </ThemeProvider>
        </PostHogProvider>
    )
}

export default App