import { useCallback, useMemo, useState } from "react"
import { marked } from "marked"

import Footer from "app/components/Footer"
import BlockBehindAuth from "app/components/BlockBehindAuth"

import { ReactComponent as IconExpand } from "app/svg/expand.svg"

import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import Loader from "common/src/components/Loader"

import user from "common/src/user"
import useDictRef from "common/src/hooks/useDictRef"

function getTabs(level) {
    return "\t".repeat(level - 1);
}

function addParagraph(line) {
    return line + "\n\n";
}

function addList(list, level = 1) {
    let line = "";

    list.forEach(entry => {
        if (typeof entry === "string") {
            line += getTabs(level) + "* " + entry + "\n";
        }
        else {
            line += addList(entry, level + 1);
        }
    });
    line += "\n";

    return line;
}

function prepareMd(a) {
    if (typeof a === "string") {
        return a;
    }

    let md = "";
    a.forEach(entry => {
        if (typeof entry === "string") {
            md += addParagraph(entry);
        }
        else {
            md += addList(entry, 1);
        }
    });

    return md;
}

function prepareHtml(md) {
    return marked.parse(md);
}

function A({ a }) {

    const md = useMemo(() => prepareMd(a), [ a ]);
    const html = useMemo(() => prepareHtml(md), [ md ]);

    return (
        <div className="page-faq-entry-a" dangerouslySetInnerHTML={{ __html: html }}/>
    )
}

function QA({ qa }) {

    const [ open, setOpen ] = useState(false);
    const onToggle = useSwallowEventCallback(
        () => setOpen(!open),
        [ open ]
    );
    const cls = useMemo(
        () => ["page-faq-entry", open ? "open" : ""].join(" "),
        [ open ]
    );

    return (
        <div className={ cls }>
            <a className="page-faq-entry-q" onClick={ onToggle } href="/#">
                <h4>{ qa.q }</h4>
                <IconExpand/>
            </a>
            { open && <A a={ qa.a }/> }
        </div>
    )
};

function matchAccess(entry) {
    if (typeof entry === "string") {
        return true;
    }
    if (entry.li === undefined) {
        return true;
    }
    return entry.li === user.loggedIn();
}

function filterAnswers(a) {
    if (typeof a === "string") {
        return a;
    }
    if (Array.isArray(a)) {
        return a.map(sa => filterAnswers(sa)).filter(sa => sa !== null);
    }
    if (typeof a === "object") {
        return matchAccess(a) ? a.text : null;
    }
    return a;
}

function filterFaqs(faqs) {

    let filtered = faqs.filter(s => matchAccess(s));
    filtered.forEach(s => {
        s.children = s.children.filter(e => matchAccess(e));
        s.children.forEach(qa => { qa.a = filterAnswers(qa.a) })
    });

    return filtered.filter(s => s.children.length > 0);
}

const titles = {
    default: "FAQs",
    shopper: "PSE FAQs",
    client: "Client FAQs"
};




function PageFAQ(props) {

    let routeRole = props.match?.params?.mode || "default";
    if (!titles[routeRole]) {
        routeRole = "default";
    }

    const [ loading, setLoading ] = useState(false);
    const [ qas, setQas ] = useState([]);
    const [ authState, setAuthState ] = useState("checking");
    const [ defaultRole, setDefaultRole ] = useState("shopper");
    const ref = useDictRef({ defaultRole, routeRole });

    const loadFaqs = useCallback(
        async (role) => {
            setLoading(true);

            role = role === "shopper" ? "pse" : "ec";
            setQas([]);
            await fetch(`https://assets.thefloorr.com/content/${ role }-faq.json`, { mode: "cors" })
                    .then(r => r.json())
                    .then(faqs => filterFaqs(faqs))
                    .then(setQas);
            
            setLoading(false);
        },
        []
    );

    const onStateChange = useCallback(
        (role, state) => {
            setAuthState(state);
            if (state === "show") {
                if (role === "default") {
                    role = ref.defaultRole;
                }
                loadFaqs(role);
            }
        },
        // eslint-disable-next-line
        []
    );

    const onDefaultRoleChange = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            const role = e.target.dataset.role;
            setDefaultRole(role);
            loadFaqs(role);
        },
        // eslint-disable-next-line
        []
    );
    
    return (
        <>
        <div className="page-faq">
            { (routeRole === "default" && !user.loggedIn()) && 
                <div className="page-faq-menu">
                    <a href="/#" 
                        data-role="shopper"
                        onClick={ onDefaultRoleChange }
                        className={ defaultRole === "shopper" ? "active" : ""}>Personal Shopper Stylist</a>
                    <a href="/#" 
                        data-role="client"
                        onClick={ onDefaultRoleChange }
                        className={ defaultRole === "client" ? "active" : ""}>Client</a>
                </div> }
            <BlockBehindAuth routeMatch={ routeRole } onStateChange={ onStateChange }>
                { loading && <Loader size={ 50 }/> }
                { qas.map((section, index) => (
                    <div key={ index } className="page-faq-section">
                        <h2 className="h-primary">{ section.q }</h2>
                        <div className="page-faq-section-content">
                        { section.children.map((f, index) => (<QA qa={ f } key={ index }/>)) }
                        </div>
                    </div>
                ))}
            </BlockBehindAuth>
        </div>
        { (authState === "show" || routeRole === "default") && <Footer/> }
        </>
    )
}

export default PageFAQ