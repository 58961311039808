
import React from "react"

import Footer from "app/components/Footer"

import useHtmlContent from "common/src/hooks/useHtmlContent"


function Privacy() {

    const html = useHtmlContent("privacy");

    return (
        <>
            <div className="page page-terms">
                <h1 className="h-primary">THE FLOORR Privacy Notice</h1>
                <div className="terms" dangerouslySetInnerHTML={ html }></div>
            </div>  
            <Footer/>
        </>
    )
}

export default Privacy