import { useEffect } from "react"
import { isMobile, isIOS } from "react-device-detect"

import TFSmartBanner from "common/src/components/SmartBanner"
import settings from "app/settings"
import Footer from "app/components/Footer"

import { ReactComponent as AppStoreSvg } from "common/src/svg/app-store-badge2.svg"

//const DOWNLOAD_URL = "https://apps.apple.com/us/app/the-floorr-client/id6451112918";

function Download() {

    useEffect(
        () => {
            if (isMobile && isIOS) {
                // window.location.href = settings.appStoreUrl;
            }
        },
        []
    );

    return (
        <>
            <div className="page-download">
                <TFSmartBanner itunesId={ settings.itunesId }/>
                <h2 className="page-download-title"> 
                    Download the app
                </h2>
                <p className="page-download-text">
                    For the best experience, download THE FLOORR App.
                </p>
                <a href={ settings.appStoreUrl } rel="noreferrer" target="_blank">
                    <AppStoreSvg/>
                </a>
            </div>
            <Footer/>
        </>

    )
}

export default Download