import React from "react"

import settings from "common/src/settings"
import retailers from "common/src/lib/catalogue/retailers"


function HomeAffiliates() {

    const u = settings.assetsUrl + "/logos";
    const rs = {};
    retailers.forEach(r => rs[ r.id ] = r.logo ? `${ u }/${ r.logo.black || r.logo.white }` : '' );

    return (
        <>
            <a href="https://www.bergdorfgoodman.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-goodman"
                        width="533" height="45" 
                        src={ rs.bergdorf } 
                        alt="Bergdorf Goodman"/>
            </a>
            <a href="https://www.brownsfashion.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-browns" src={ rs.browns } alt="Browns"/>
            </a>
            <a href="https://www.chloe.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-chloe" src={ rs.chloe } alt="Chloe"/>
            </a>
            {/*<a href="https://www.etro.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-etro"
                    width="2834" height="296" 
                    src={ rs.etro } 
                    alt="Etro"/>
            </a>*/}
            <a href="https://www.farfetch.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-ff"
                    width="1250" height="159" 
                    src={ rs.farfetch } 
                    alt="Farfetch"/>
            </a>
            {/*<a href="https://www.fwrd.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-forward"
                    width="982" height="115"
                    src={ rs.forward } 
                    alt="Forward"/>
            </a>*/}
            {/*<a href="https://www.gucci.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-gucci"
                    width="281" height="45"
                    src={ rs.gucci } 
                    alt="Gucci"/>
            </a>*/}
            {/*<a href="https://www.jimmychoo.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-jc" src={ rs.jimmychoo } alt="Jimmy Choo"/>
            </a>*/}
            {/*<a href="https://www.harveynichols.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-hn"
                    width="517" height="41" 
                    src={ rs.harveynichols } 
                    alt="Harvey Nichols"/>
            </a>*/}
            <a href="https://www.luisaviaroma.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-lvr"
                    width="489" height="52"
                    src={ rs.luisaviaroma } 
                    alt="Luisaviaroma"/>
            </a>
            {/*<a href="https://www.matchesfashion.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-matches"
                    width="814" height="42" 
                    src={ rs.matchesfashion } 
                    alt="Matches Fashion"/>
            </a>*/}
            <a href="https://www.modaoperandi.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-moda"
                    width="596" height="139" 
                    src={ rs.modaoperandi } 
                    alt="Moda Operandi"/>
            </a>
            <a href="https://www.mytheresa.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-mytheresa"
                    width="566" height="70"
                    src={ rs.mytheresa } 
                    alt="My Theresa"/>
            </a>
            <a href="https://www.neimanmarcus.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-neiman"
                    width="588" height="211" 
                    src={ rs.neiman } 
                    alt="Neiman Marcus"/>
            </a>
            {/*<a href="https://www.neous.co.uk/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-neous"
                    width="672" height="175"
                    src={ rs.neous } 
                    alt="Neous"/>
            </a>*/}
            <a href="https://www.net-a-porter.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-nap"
                    width="579" height="46"
                    src={ rs['net-a-porter'] } 
                    alt="Net-a-porter"/>
            </a>
            <a href="https://www.ralphlauren.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-rl"
                    width="426" height="31" 
                    src={ rs.rl } 
                    alt="Ralph Lauren"/>
            </a>
            {/*<a href="https://www.rogervivier.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-rvivier"
                    width="113" height="35" 
                    src={ rs.rogervivier } 
                    alt="Roger Vivier"/>
            </a>*/}
            <a href="https://www.saksfifthavenue.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-saks" 
                    src={ rs.saks } 
                    alt="Saks Fifth Avenue"/>
            </a>
            <a href="https://www.ferragamo.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-sf"
                    width="877" height="167" 
                    src={ rs.ferragamo } 
                    alt="Salvatore Ferragamo"/>
            </a>
            <a href="https://www.selfridges.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="logo-selfridges" 
                    src={ rs.selfridges } 
                    alt="Selfridges"/>
            </a>
            {/*<a href="https://shopbop.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-shopbop"
                    width="342" height="84" 
                    src={ rs.shopbop } 
                    alt="Shopbop"/>
            </a>*/}
            <a href="https://www.ssense.com/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-ssense"
                    width="382" height="72"
                    src={ rs.ssense } 
                    alt="Ssense"/>
            </a>
            {/*<a href="https://thewebster.us/" 
                target="_blank" 
                rel="noopener noreferrer">
                <img className="icon icon-svg-fill icon-logo-webster"
                    width="133" height="14" 
                    src={ rs.webster } 
                    alt="The Webster"/>
            </a>*/}
        </>
    )
}

export default HomeAffiliates
