import { useMemo } from "react"


function Spacer({ width = 0, height = 0 }) {

    const style = useMemo(
        () => ({
            width: width + "px",
            height: height + "px"
        }),
        [ width, height ]
    );
 
    return (
        <div style={ style }/>
    )
}

export default Spacer