import api from "common/src/api"
import entities from "common/src/api/hasura/entities"


Object.assign(entities, 
{

});

export const init = () => {
    api.init();
}

export default api