import { useCallback, useMemo } from "react"


function TextField({ className, variant = "default", disabled = false, multiline = false,
                    value, onChange, onKeyDown, inputProps, left, right }) {

    const cls = useMemo(
        () => [
            "text-field",
            variant,
            className,
            multiline ? "multiline" : "",
            disabled ? "disabled" : ""
        ].join(" "),
        [ variant, className, disabled, multiline ]
    );

    const onValueChange = useCallback(
        (e) => onChange(e.target.value),
        [ onChange ]
    );


    return (
        <div className={ cls }>
            { left }
            { multiline ? 
                <textarea
                    value={ value }
                    onChange={ onValueChange } 
                    onKeyDown={ onKeyDown }
                    { ...inputProps }/> :
                <input 
                    value={ value } 
                    onChange={ onValueChange } 
                    onKeyDown={ onKeyDown }
                    { ...inputProps }/> }
            { right }
        </div>
    )
}

export default TextField