
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { signOut } from "common/src/actions/user"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";

function NavSecondary() {
    const loggedIn = useSelector(s => s.user.loggedIn);

    const onSignoutPress = useSwallowEventCallback(
        async () => {
            signOut();
        },
        []
    );

    return (
        <>
            <NavLink to="/faq">FAQs</NavLink>
            <NavLink to="/contact">Contact Us</NavLink>
            <NavLink to="/terms">Terms & Conditions</NavLink>
            <NavLink to="/privacy">Privacy Notice</NavLink>
            { loggedIn && <a href="/#" onClick={ onSignoutPress }>Log Out</a>}
        </>
    )
}

export default NavSecondary

//<a href="/#">FAQS</a>