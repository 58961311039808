
export default async function validateInstagramHandle(value) {
    if (!value) {
        return true;
    }
    if (value.match(/https?:\//i)) {
        return false;
    }
    if (value.match(/[\s/]/)) {
        return false;
    }
    return true;
}