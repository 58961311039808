import { useCallback, useMemo } from "react"

import FormFieldContainer from "./FormFieldContainer";
import TextField from "./TextField"

function FormField({ label, error, className,
                    value, onChange, onSubmit, 
                    left, right, 
                    inputProps = {}, style, variant, multiline = false }) {

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter" && onSubmit) {
                onSubmit();
            }
        },
        [ onSubmit ]
    );

    const inputClassName = useMemo(
        () => [ className, error ? "error" : "" ].join(" "),
        [ className, error ]
    );

    return (
        <FormFieldContainer
            label={ label }
            style={ style }
            error={ error }>
            <TextField 
                className={ inputClassName }
                variant={ variant }
                value={ value }
                left={ left }
                right={ right }
                multiline={ multiline }
                onKeyDown={ onKeyDown }
                onChange={ onChange } 
                inputProps={ inputProps }/>
        </FormFieldContainer>
    )
}

export default FormField