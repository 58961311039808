import { useEffect } from "react";
import { useLocation } from "react-router-dom"

import BurgerMenu from "./BurgerMenu"
import hub from "common/src/hub"

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(
        () => {
            hub.dispatch("app", "show-shopper-form", false);
            hub.dispatch("app", "show-brand-form", false);
            hub.dispatch("app", "show-customer-form", false);
            BurgerMenu.hide();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }, 
        [ pathname ]
    );

    return null;
}
