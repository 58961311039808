import { Helmet } from "react-helmet"
import SmartBanner from "react-smartbanner"
import { isMobile, isIOS, isSafari } from "react-device-detect"
import "react-smartbanner/dist/main.css"
import { useEffect, useState } from "react"


function TFSmartBanner({ title = "The Floorr", itunesId = process.env.REACT_APP_ITUNES_ID }) {

    const [ show, setShow ] = useState(false);

    useEffect(
        () => {
            setTimeout(() => setShow(true), 1000);
        },
        []
    );

    if (!itunesId) {
        return null;
    }

    if (isMobile && isIOS) {
        return (
            <>
            <Helmet>
                <meta name="apple-itunes-app" content={ `app-id=${ itunesId }` }/>
            </Helmet>
            { (!isSafari && show) &&           
                <SmartBanner 
                    title={ title } 
                    daysHidden={0} 
                    daysReminder={0}/> }
            </>
        )
    }

    return null;
}

export default TFSmartBanner