import { useMemo } from "react"
import Loader from "common/src/components/Loader"


function Button({ text, loading = false, disabled = false, active = false,
                    variant = "default", className, children, before, after,
                    onClick }) {

    const cls = useMemo(
        () => {
            return [
                "button",
                variant,
                className,
                disabled ? "disabled" : "",
                active ? "active" : "",
                loading ? "loading" : ""
            ].join(" ");
        },
        [ variant, className, disabled, loading, active ]
    );

    return (
        <button className={ cls } onClick={ onClick } disabled={ disabled }>
            { before }
            { loading && <Loader size={ 20 }/> }
            { !loading && text }
            { children }
            { after }
        </button>
    )
}

export default Button