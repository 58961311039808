
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import NavPrimary from "./nav/Primary"
import NavSecondary from "./nav/Secondary"
import NavSocial from "./nav/Social"

import store from "app/store"
import { ui as ddUi } from "common/src/store/dialogs"

const DIALOG_NAME = "burger-menu";

function BurgerMenu() {

    const open = useSelector(s => s.dialogs[ DIALOG_NAME ]);
    const cls = useMemo(() => `burger-menu ${ open ? "open" : "" }`, [ open ]);

    useEffect(
        () => {
            if (open) {
                document.documentElement.classList.add("burger-open");
            }
            else {
                document.documentElement.classList.remove("burger-open");
            }
        },
        [ open ]
    )

    return (
        <div className={ cls }>
            <nav className="burger-menu-nav-main">
                <NavPrimary expandableAbout/>
            </nav>

            <nav className="burger-menu-nav-secondary">
                <NavSecondary/>
            </nav>

            <nav className="burger-menu-nav-social">
                <NavSocial/>
            </nav>
        </div>
    )
}

BurgerMenu.show = () => {
    store.dispatch(ddUi.show(DIALOG_NAME));
};

BurgerMenu.hide = () => {
    store.dispatch(ddUi.hide(DIALOG_NAME));
};

BurgerMenu.DIALOG_NAME = DIALOG_NAME;

export default BurgerMenu