
import { ReactComponent as IconFacebook } from "app/svg/facebook.svg"
import { ReactComponent as IconInstagram } from "app/svg/instagram.svg"
import { ReactComponent as IconLinkedin } from "app/svg/linkedin.svg"
import { ReactComponent as IconPinterest } from "app/svg/pinterest.svg"
import { ReactComponent as IconTiktok } from "app/svg/tiktok.svg"


function NavSocial() {

    return (
        <>
            <a href="https://www.facebook.com/profile.php?id=61553465714507&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><IconFacebook/></a>
            <a href="https://www.instagram.com/thefloorrfashion" target="_blank" rel="noopener noreferrer"><IconInstagram/></a>
            <a href="https://www.linkedin.com/company/thefloorr" target="_blank" rel="noopener noreferrer"><IconLinkedin/></a>
            <a href="https://www.pinterest.ru/thefloorrfashion" target="_blank" rel="noopener noreferrer"><IconPinterest/></a>
            <a href="https://www.tiktok.com/@thefloorrfashion" target="_blank" rel="noopener noreferrer"><IconTiktok/></a>
        </>
    )
}

export default NavSocial