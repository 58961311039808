
import auth from "./auth";
import posthog from "common/src/listeners/posthog"

const listeners = {
    init: () => {
        auth.init();
        posthog.init();
    }
}

export default listeners