import { useCallback, useState, useEffect, useMemo } from "react";
import Spacer from "../Spacer"
import getDialCodes from "common/src/lib/getDialCodes"

import Select from "app/components/form/Select"
import TextField from "app/components/form/TextField"

function PhoneField({ value={}, onChange, onSubmit, className, 
                      variant = "default", disabled = false,
                      inputProps, error, spacerSize=6 }) {

    const [ dialCodes, setDialCodes ] = useState([]);

    const cls = useMemo(
        () => [
            "phone-field",
            className,
            variant,
            disabled ? "disabled" : ""
        ].join(" "),
        [ className, variant, disabled ]
    );

    const selectCls = useMemo(
        () => [
            "phone-field-select-field",
            className ? className +"-select-field" : "",
            error ? "error" : ""
        ].join(" "),
        [ className, error ]
    );

    const inputCls = useMemo(
        () => [ 
            "phone-field-text-field",
            className ? className +"-text-field" : "",
            error ? "error" : ""
        ].join(" "),
        [ className, error ]
    );

    const loadDialCodes = useCallback(
        async () => {
            const codes = await getDialCodes();
            const options = codes.map(c => ({
                name: "+" + c.dial,
                label: "+" + c.dial + " " + c.name,
                value: c.dial
            }));
            setDialCodes(options);
        },
        []
    );

    const onDialCodeChange = useCallback(
        (dialCode) => onChange({ dialCode, number: value?.number || "" }),
        [ value?.number, onChange ]
    );
    
    const onValueChange = useCallback(
        (number) => onChange({ dialCode: value?.dialCode || "", number }),
        [ value?.dialCode, onChange ]
    );

    const renderDialCode = useCallback(
        (code, defaultCode) => "+" + (code || defaultCode),
        []
    );

    useEffect(
        () => {
            loadDialCodes();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className={ cls }>
            <Select 
                className={ selectCls }
                value={ value?.dialCode } 
                defaultValue="44" 
                options={ dialCodes }
                disabled={ disabled }
                variant={ variant }
                onChange={ onDialCodeChange }
                renderValue={ renderDialCode }/>
            <Spacer width={ spacerSize }/>
            <TextField
                className={ inputCls }
                value={ value?.number }
                disabled={ disabled }
                onSubmit={ onSubmit }
                variant={ variant }
                onChange={ onValueChange }
                inputProps={ inputProps }/>
        </div>
    )
}

export default PhoneField