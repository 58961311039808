

function FormFieldContainer({ label, error, style, children }) {
    return (
        <div className="form-field" style={ style }>
            { label && <label className="form-field-label">{ label }</label> }
            { children }
            { error && <span className="form-field-error">{ error }</span> }
        </div>
    )
}

export default FormFieldContainer