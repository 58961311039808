
import prepare from "common/src/lib/store/prepare"
import * as user from "common/src/store/user"

import * as dialogs from "common/src/store/dialogs"
import * as app from "common/src/store/app"

export default prepare({
    user, 
    dialogs,
    app,

});
