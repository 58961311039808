

const gqlendpoints = {
    "devtwo": "https://devtwo-graphql.thefloorr.com/v1/graphql",
    "test": "https://test-graphql.thefloorr.com/v1/graphql",
    "live": "https://graphql.thefloorr.com/v1/graphql"
}

export default {
    "assetsUrl": "https://assets.thefloorr.com",
    "currenciesUrl": "https://assets.thefloorr.com/content/currencymap.json",
    "graphqlEndpoint": gqlendpoints[process.env.REACT_APP_ENV],
    "domains": {
        "dev": {
            "web": "https://dev-web.thefloorr.com",
            "tools": "https://dev-tools.thefloorr.com",
            "admin": "https://dev-admin.thefloorr.com"
        },
        "devtwo": {
            "web": "https://dev-web.thefloorr.com",
            "tools": "https://dev-tools.thefloorr.com",
            "admin": "https://dev-admin.thefloorr.com"
        },
        "test": {
            "web": "https://test-web.thefloorr.com",
            "tools": "https://test-tools.thefloorr.com",
            "admin": "https://test-admin.thefloorr.com"
        },
        "live": {
            "web": "https://thefloorr.com",
            "tools": "https://tools.thefloorr.com",
            "admin": "https://admin.thefloorr.com"
        }
    },
    "cloudfront": {
        "devtwo": "https://d3n92psrjbuckt.cloudfront.net",
        "test": "https://dj92c27me8n6m.cloudfront.net",
        "live": "https://dv5pyt2adss5g.cloudfront.net"
    },
    "ga": {
        "devtwo": "UA-171316135-1",
        "test": "UA-171316135-2",
        "live": "UA-171316135-3"
    },
    "posthog": {
        "tools": {
            "devtwo": "phc_DsgrjXtg7dmPNeXk9MYhVIgZo188NiaXsF33yspWu22",
            "live": "phc_Yi56pJT37u6NQDSIVqY5VzfgTNlHOVnWGRsLzr3ZI5p"
        },
        "web": {
            "devtwo": "phc_DsgrjXtg7dmPNeXk9MYhVIgZo188NiaXsF33yspWu22",
            "live": "phc_Yi56pJT37u6NQDSIVqY5VzfgTNlHOVnWGRsLzr3ZI5p"
        }
    },
    "productExpirationDays": 10
}