
import React, { useEffect, useRef } from "react"

import Footer from "app/components/Footer"


function Cookies() {

    const ref = useRef();
    const scriptRef = useRef();

    useEffect(
        () => {

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = "https://consent.cookiebot.com/00e09d01-eea6-48a2-b18a-a6f97734d1ca/cd.js";
            s.id = "CookieDeclaration";
            ref.current.appendChild(s);
            scriptRef.current = s;

            return () => {
                if (scriptRef.current && ref.current) {
                    // eslint-disable-next-line
                    ref.current.removeChild(scriptRef.current);
                    scriptRef.current = null;
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <div className="page page-terms">
                <h1 className="h-primary">Cookies</h1>
                <div ref={ ref } className="table-content"/>
            </div>  
            <Footer/>
        </>
    )
}

export default Cookies