
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import { useEffect, useState } from "react"


function useHtmlClass(className) {

    const [ currentClassName, setCurrentClassName ] = useState(className);

    useUpdateEffect(
        () => {
            if (currentClassName) {
                document.documentElement.classList.remove(currentClassName);
            }
            if (className) {
                document.documentElement.classList.add(className);
            }
            setCurrentClassName(className);
        },
        [ className ]
    );

    useEffect(
        () => {
            if (className) {
                document.documentElement.classList.add(className);
            }

            return () => {
                if (currentClassName) {
                    document.documentElement.classList.remove(currentClassName);
                }
            };
        },
        // eslint-disable-next-line
        []
    );
}

export default useHtmlClass