
//import { ReactComponent as IconTick } from "common/src/svg/tick.svg"
import { useCallback, useMemo } from "react"

function Checkbox({ checked, label, onChange, error }) {

    const onCheckedChange = useCallback(
        () => onChange(!checked),
        [ checked, onChange ]
    );

    const cls = useMemo(
        () => {
            return [
                "checkbox",
                checked ? "checked" : "",
                error ? "error" : ""
            ].join(" ");
        },
        [ error, checked ]
    );

    return (
        <div className={ cls }>
            <label>
                <div className="checkbox-tick">{ checked && <div/> }</div>
                <span>{ label }</span>
                <input 
                    type="checkbox" 
                    value="1" 
                    checked={ checked }
                    onChange={ onCheckedChange }/>
            </label>
            { error && <span className="form-field-error">{ error }</span>}
        </div>
    )
}

export default Checkbox