import { useCallback, useMemo } from "react"

import { ReactComponent as IconExpand } from "app/svg/expand.svg"

function Select({ value, onChange, options, 
                    className, variant = "default", error,
                    disabled = false, defaultValue = null, placeholder = "",
                    emptyOption = false,
                    renderValue = null }) {

    const onValueChange = useCallback(
        (e) => onChange(e.target.value),
        [ onChange ]
    );

    const cls = useMemo(
        () => [
            "select-field",
            className,
            variant,
            disabled ? "disabled" : "",
            error ? "error" : ""
        ].join(" "),
        [ className, variant, disabled, error ]
    );

    const renderedValue = useMemo(
        () => {
            if (renderValue) {
                return renderValue(value, defaultValue);
            } else if ((value !== "undefined" || value !== null) && !!value) {
                return value
            } else if (typeof defaultValue !== "undefined" && defaultValue !== null) {
                return defaultValue
            }
            return placeholder || ""
        },
        [ value, renderValue, defaultValue, placeholder ]
    );

    const selectValue = useMemo(
        () =>  typeof value !== "undefined" || value !== null ?
                    value:
                    typeof defaultValue !== "undefined" || defaultValue !== null ?
                        defaultValue : "",
        [ value, defaultValue ]
    );

    return (
        <div className={ cls }>
            { renderedValue }
            <IconExpand/>
            <select
                value={ selectValue }
                onChange={ onValueChange }>
                { emptyOption && <option>{ emptyOption === true ? "" : emptyOption }</option> }
                { options.map((o, inx) => <option 
                                            key={ inx } 
                                            value={ o.value }>
                                            { o.label }
                                            </option>)}
            </select>
        </div>
    )
}

export default Select