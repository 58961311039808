import hub from "common/src/hub"
import { useMemo } from "react"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";

function Alert({ message, title, overlay = false, variant = "default" }) {
    
    const onOk = useSwallowEventCallback(
        () => hub.dispatch("alert", "dismiss"),
        []
    );

    const { overlayCls, alertCls } = useMemo(
        () => {
            const alertCls = [ "alert", variant ].join(" ");
            const overlayCls = [ "alert-overlay", variant ].join(" ");
            return { alertCls, overlayCls };
        },
        [ variant ]
    );

    return (
        <>
        { overlay && <div className={ overlayCls }/> }
        <div className={ alertCls }>
            <a href="/#" onClick={ onOk } className="alert-close"><IconClose/></a>
            { title && <p className="alert-title">{ title }</p> }
            <p className="alert-message">{ message }</p>
        </div>
        </>
    )
}

export default Alert