
import { useCallback, useState } from "react"
import { Link } from "react-router-dom"

//import Loader from "common/src/components/Loader"
import FormField from "app/components/form/FormField"
import Button from "app/components/Button"
import Checkbox from "app/components/form/Checkbox"
import PhoneField from "app/components/form/PhoneField"
import FormFieldContainer from "app/components/form/FormFieldContainer"

import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import useDictRef from "common/src/hooks/useDictRef"
import isEmail from "common/src/lib/form/validator/isEmail"
import hub from "common/src/hub"
import api from "app/api"

import required from "common/src/lib/form/validator/required"
import createValidator from "common/src/lib/form/createValidator"
import useFormData from "common/src/lib/form/useFormData"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"




async function phoneRequired(value) {
    return await required(value?.number);
}

async function validateEmail(email) {
    const res = await api.unauth.post("/ec/validate-email", { body: { email }});
    return res?.success || false;
}

async function validatePSEmail(email) {
    if (!email) {
        return true;
    }
    return isEmail(email);
}



const fields = [
    {
        name: "name",
        validator: createValidator(required, "Please enter your first name")
    },
    {
        name: "surname",
        validator: createValidator(required, "Please enter your last name")
    },
    {
        name: "email",
        validator: [
            createValidator(required, "Please enter your email"),
            createValidator(isEmail, "Please enter a valid email"),
            createValidator(validateEmail, "Sorry, you can't use this email")
        ]
    },
    {
        name: "phone",
        validator: createValidator(phoneRequired, "Please enter your phone number"),
        default: () => ({
            dialCode: "44",
            number: ""
        }),
    },
    {
        name: "shopperName",
    },
    {
        name: "shopperSurname",
    },
    {
        name: "shopperEmail",
        validator: [
            createValidator(validatePSEmail, "Please enter a valid email"),
        ]
    },
    {
        name: "agree",
        validator: createValidator(required, "You have to agree to our terms and conditions")
    }
]


function CustomerForm() {

    const {
        validateAll, setErrorMode, resetAll,
        name, nameError, nameChange,
        surname, surnameError, surnameChange,
        email, emailError, emailChange,
        phone, phoneError, phoneChange,
        shopperName, shopperNameChange,
        shopperSurname, shopperSurnameChange,
        shopperEmail, shopperEmailError, shopperEmailChange,
        agree, agreeError, agreeChange
    } = useFormData(fields);

    const [ saving, setSaving ] = useState(false);

    const ref = useDictRef({
        name, surname, shopperName, shopperSurname, shopperEmail, email, agree, phone
    });

    const onSubmit = useCallback(
        async () => {
            
            setSaving(true);

            const valid = await validateAll();

            if (valid) {
    
                const phoneNum = ref.phone.dialCode && ref.phone.number ?
                                    ref.phone.dialCode + " " + ref.phone.number :
                                    "";

                await api.unauth.post("/ec/apply", {
                    body: {
                        name: ref.name,
                        surname: ref.surname,
                        email: ref.email,
                        phone: phoneNum,
                        shopperName: ref.shopperName,
                        shopperSurname: ref.shopperSurname,
                        shopperEmail: ref.shopperEmail,
                        role: "customer",
                        createdVia: "website/customer/form"
                    }
                });

                hub.dispatch("app", "show-customer-form", false);
                hub.dispatch("alert", "show", {
                    overlay: true,
                    variant: "default-inverted",
                    title: "We're building something special for you.",
                    message: "Thank you for joining THE FLOORR community - luxury's secret weapon. " +
                            "A place for the customer facing community, luxury brands and clients to thrive."
                });

                resetAll();
            }
            else {
                setErrorMode(true);
            }

            setSaving(false);
        },
        // eslint-disable-next-line
        []
    );

    const onCloseClick = useSwallowEventCallback(
        () => hub.dispatch("app", "show-customer-form", false),
        []
    );

    return (
        <div className="connect-dialog-container">
        <div className="connect-dialog">
            
            
            <div className="connect-form">
                <h2 className="h-primary connect-dialog-header">Client</h2>
                <p className="connect-dialog-subheader">If you are a Client, please fill this form. 
                    If you are a Personal Shopper or if you are a Brand or a Retailer, please go back.</p>

                <div className="form-row">
                    <FormField
                        name="name"
                        variant="transparent"
                        value={ name }
                        error={ nameError }
                        onSubmit={ onSubmit }
                        onChange={ nameChange }
                        inputProps={{
                            placeholder: "Your first name *"
                        }}/>
                </div>
                <div className="form-row">
                    <FormField
                        name="surname"
                        variant="transparent"
                        error={ surnameError }
                        value={ surname }
                        onSubmit={ onSubmit }
                        onChange={ surnameChange }
                        inputProps={{
                            placeholder: "Your last name *"
                        }}/>
                </div>
                <div className="form-row">
                    <FormField
                        name="email"
                        variant="transparent"
                        value={ email }
                        error={ emailError }
                        onSubmit={ onSubmit }
                        onChange={ emailChange }
                        inputProps={{
                            placeholder: "Your email address *"
                        }}/>
                </div>
                <div className="form-row">
                    <FormFieldContainer error={ phoneError }>
                        <PhoneField
                            name="phone"
                            variant="transparent"
                            value={ phone }
                            onChange={ phoneChange }
                            error={ phoneError }
                            inputProps={{
                                placeholder: "Phone Number *"
                            }}/>
                    </FormFieldContainer>
                </div>
                <div className="form-row">
                    <FormField
                        name="shopperName"
                        variant="transparent"
                        value={ shopperName }
                        onSubmit={ onSubmit }
                        onChange={ shopperNameChange }
                        inputProps={{
                            placeholder: "Personal shopper first name"
                        }}/>
                </div>
                <div className="form-row">
                    <FormField
                        name="shopperSurname"
                        variant="transparent"
                        value={ shopperSurname }
                        onSubmit={ onSubmit }
                        onChange={ shopperSurnameChange }
                        inputProps={{
                            placeholder: "Personal shopper last name"
                        }}/>
                </div>
                <div className="form-row">
                    <FormField
                        name="shopperEmail"
                        variant="transparent"
                        value={ shopperEmail }
                        error={ shopperEmailError }
                        onSubmit={ onSubmit }
                        onChange={ shopperEmailChange }
                        inputProps={{
                            placeholder: "Personal shopper email address"
                        }}/>
                </div>
                <div className="form-row">
                    <Checkbox
                        checked={ agree }
                        error={ agreeError }
                        onChange={ agreeChange }
                        label={ 
                            <p className="connect-terms">
                                By submitting this form you agree to our&nbsp;
                                <Link to="/terms" target="_blank" rel="noreferrer noopener">
                                    <nobr>terms of use</nobr>
                                </Link>&nbsp;
                                and&nbsp;
                                <Link to="/privacy" target="_blank" rel="noreferrer noopener">
                                    <nobr>privacy notice</nobr>
                                </Link>.</p> }/>
                </div>

                <Button 
                    text="Apply Now"
                    variant="white"
                    loading={ saving }
                    onClick={ onSubmit }
                    className="connect-action" 
                    disabled={ saving }/>
                <Button 
                    text="Go Back"
                    variant="text"
                    className="connect-action-close"
                    onClick={ onCloseClick }/>
            </div>
        </div>
        <a href="/#" className="connect-dialog-close" onClick={ onCloseClick }>
            <IconClose/>
        </a>
        </div>
    )
}

export default CustomerForm
