import Footer from "../Footer";
import useHtmlContent from "common/src/hooks/useHtmlContent";

function AcceptableUsePolicy() {
    const html = useHtmlContent("acceptable-use-policy");

    return (
        <>
            <div className="page page-terms">
                <div className="terms" dangerouslySetInnerHTML={ html }></div>
            </div>  
            <Footer/>
        </>
    )
}

export default AcceptableUsePolicy