import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { checkCurrent } from "common/src/actions/user"
import user from "common/src/user"
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function getUserMode() {
    if (user.isOnly("User")) {
        return "client";
    }
    return "shopper";
}

function useTermsAccess(defaultMode = "default") {

    const { loggedIn } = useSelector(s => s.user);
    const [ mode, setMode ] = useState(loggedIn === true ? getUserMode() : defaultMode);
    const [ state, setState ] = useState(loggedIn !== null ? "show" : "checking");
    

    useUpdateEffect(
        () => {
            setMode(loggedIn === true ? getUserMode() : defaultMode);
            setState("show");
        },
        [ loggedIn ]
    );


    useEffect(
        () => {
            if (loggedIn === null) {
                checkCurrent();
            }
        },
        // eslint-disable-next-line
        []
    );

    return { mode, state };

}

export default useTermsAccess