
import { useCallback, useEffect, useMemo, useState } from "react"

import Footer from "app/components/Footer"
import Loader from "common/src/components/Loader"

import useQueryParam from "common/src/hooks/useQueryParam"

const status2message = {
    failed: "Failed to verify email.",
    invalid: "Verification link is invalid",
    expired: "Verification link is expired",
    used: "Verification link has already been used",
    verified: "Email successfully verified"
}

function PageEmail() {

    const reqId = useQueryParam("id");
    const code = useQueryParam("emailVerificationCode");
    const [ checking, setChecking ] = useState(false);
    const [ reqStatus, setReqStatus ] = useState(null);
    //const goToApp = !reqId || !code;
    //const status = props.match?.params?.status;

    const checkRequestById = useCallback(
        async (id, code) => {
            setChecking(true);

            const domain = process.env.REACT_APP_ENV === "live" ? 
                            "api-backend.thefloorr.com" : 
                            `api-backend-${ process.env.REACT_APP_ENV }.thefloorr.com`;
            const url = `https://${ domain }/public/auto-verify-email?`+
                        `id=${ id }&code=${ code }&returnStatus=true`

            const res = await fetch(url).then(res => res.json());
            const { status } = res;

            setReqStatus(status);
            setChecking(false);
        },
        []
    );

    const { state, status } = useMemo(
        () => {
            let state = "";
            let status = null;

            if (!code || !reqId) {
                state = "status";
                status = "invalid";
            }
            else if (reqStatus) {
                state = "status";
                status = reqStatus;
            }
            else if (checking) {
                state = "loading";
            }

            return { state, status };
        },
        [ reqId, code, checking, reqStatus ]
    )

    useEffect(
        () => {
            if (reqId && code) {
                checkRequestById(reqId, code);
            }
        },
        // eslint-disable-next-line
        []
    );
    

    return (
        <>
        <div className="page-email">
            <h1 className="h-primary">Email verification</h1>
            { state === "loading" && <Loader size={ 50 }/> }

            { state === "status" && 
                <p className={ `page-email-message page-email-message-${ status }` }>
                    { status2message[status] }
                </p> }
        </div>
        <Footer/>
        </>
    )
}

export default PageEmail