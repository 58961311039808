import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { ReactComponent as Logo } from "common/src/svg/full_logo.svg"
import { ReactComponent as IconBurger } from "common/src/svg/burger2.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import BurgerMenu from "./BurgerMenu"
import NavPrimary from "./nav/Primary"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { useCallback } from "react"


function TopMenu() {

    const burgerOpen = useSelector(s => s.dialogs[ BurgerMenu.DIALOG_NAME ]);

    const onOpenClick = useSwallowEventCallback(
        () => BurgerMenu.show(),
        []
    );

    const onCloseClick = useSwallowEventCallback(
        () => BurgerMenu.hide(),
        []
    );

    const onLogoClick = useCallback(
        () => {
            const el = document.getElementById("how-it-works");
            if (el) {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        },
        []
    );

    return (
        <div className="top-menu">
            <div className="top-menu-content">

                <Link 
                    to="/" 
                    onClick={ onLogoClick }
                    className="top-menu-logo-link">
                    <Logo/>
                </Link>

                <nav className="top-menu-nav">
                    <NavPrimary/>
                </nav>

                { !burgerOpen && 
                    <a href="/#" className="top-menu-burger" onClick={ onOpenClick }>
                        <IconBurger/>
                    </a> }
                { burgerOpen && 
                    <a href="/#" className="top-menu-close" onClick={ onCloseClick }>
                        <IconClose/>
                    </a> }
            </div>
        </div>
    )
}

export default TopMenu