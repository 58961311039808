import Footer from "app/components/Footer"

function PageMission() {

    return (
        <>
        <div className="page-mission">
            <div className="page-mission-b1">
                <h2 className="h-primary">Our Mission</h2>
                <p>Our mission is to empower the client-facing community by 
                    equipping them with the tools and technology they deserve.
                <br/><br/>
                THE FLOORR is a space where our community can leverage their 
                extensive reach and explore their limitless potential.</p>
            </div>

            <div className="page-mission-b2">
                <h2 className="h-primary">Our Goal</h2>
                <p>Our goal is to streamline our community's workflow. 
                    We have built powerful technology and tools to help style experts 
                    efficiently manage and expand their businesses.</p>
            </div>

            <div className="page-mission-quote">
                <blockquote>
                “We are empowering one of the biggest workforces in fashion, 
                placing them at the forefront of our industry.”
                </blockquote>
            </div>

            
        </div>
        <Footer/>
        </>
    )
}

export default PageMission