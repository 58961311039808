
import Footer from "app/components/Footer"


function PageFounder() {

    return (
        <>
        <div className="page-founder">

            <div className="page-founder-lupe"/>
            <div className="page-founder-content">
                <h2 className="h-primary">Our Founder</h2>
                <p>Lupe Puerta was formerly Global Head of Personal Shopping and Global Director 
                    of VIP Client Relations at NET-A-PORTER and MR PORTER. 
                    With a talent for fashion and a passion for the customer, 
                    Lupe's vision for THE FLOORR was unique: to bring the luxury shop floor's 
                    invaluable expertise into the digital realm where style-seeking 
                    clients could benefit from professional insights and advice 
                    whilst shopping online - on a global scale.</p>
            </div>

            <div className="page-founder-quote">
                <blockquote>
                “THE FLOORR comes from my innate passion for the customer and the 
                    invaluable knowledge that the luxury fashion sales collective possesses. 
                    They are the unsung heroes of the fashion industry and my aim is to put 
                    them front and centre, for the benefit of the customer.”
                </blockquote>
                <p className="page-founder-bq-by">
                    &mdash; Lupe Puerta
                </p>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default PageFounder