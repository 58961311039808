import Authenticator from "common/src/components/authenticator/Authenticator"
import useRoleAccess from "app/hooks/useRoleAccess"
import { useEffect, useMemo } from "react"
import useHtmlClass from "app/hooks/useHtmlClass";

function BlockBehindAuth({ routeMatch, children, onStateChange }) {

    const { mode, state } = useRoleAccess(routeMatch);

    useHtmlClass(state === "show" || routeMatch === "default" ? null : "html-page-signin");

    const appUrl = useMemo(
        () => {
            return routeMatch === "default" ? null :
                    routeMatch === "shopper" ? 
                        "https://apps.apple.com/us/app/the-floorr-for-stylists/id6451112463" : 
                        "https://apps.apple.com/us/app/the-floorr-for-clients/id6451112918";
        },
        [ routeMatch ]
    );

    useEffect(
        () => {
            onStateChange(mode, state);
        },
        [ mode, state, onStateChange ]
    )

    return (
        <>
            { state === "checking" ?
                <></> :
                state === "auth" ? 
                    <Authenticator 
                        authState="signIn" 
                        showRegister={ false }
                        signinProps={{
                            loaderSize: 50,
                            labelEmail: "Email Address",
                            submitText: "Log In",
                            linkForgotText: "Forgot your password?",
                            subheader: (
                                <p className="form-sub-header">
                                    If you haven't registered yet, please do so in the app.
                                    To download the app {" "}
                                    <a rel="noreferrer" href={ appUrl }>click here</a>.
                                </p>
                            )
                        }}/> :
                    state === "show" ? 
                        children :
                        null }
        </>
    )

}

export default BlockBehindAuth